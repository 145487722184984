<template>
    <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-xs-12">
        <div class="small-box pointer" v-bind:class="hasLastNewsPhoto ? 'bg-image text-gray-light' : 'bg-purple'"
             v-bind:style="hasLastNewsPhoto ?
             'background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.45)), url(' + news_item.main_photo + ');' +
             'background-position: center; background-repeat: no-repeat; background-size: cover;' : ''"
             v-on:click="$router.push('/news')">
            <div class="inner">
                <h3>{{ news_count }}</h3>
                <h5>Новостей</h5>
            </div>
            <div v-if="!hasLastNewsPhoto" class="icon">
                <i class="fas fa-newspaper"></i>
            </div>
            <a class="small-box-footer" title="Перейти" style="padding-top: 6px; padding-bottom: 6px;">
                <i class="fa font-scale fa-arrow-circle-right"></i>
            </a>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'news-box',

        props: {
            news_count: {
                type: Number,
                required: true,
                default: 0,
            },
            news_item: {
                type: [Object, null],
                required: false,
            },
        },

        computed: {
            hasLastNewsPhoto: function () {
                return this.news_item && this.news_item.main_photo;
            },
        },
    }
</script>